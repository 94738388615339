.dateInput {
  width: 78%;
  margin-left: 13px;
}
@media screen and (max-width: 767px) {
  .dateInput {
    width: 100%;
    margin-left: 0px;
  }
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 36px;
}
