@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Suez+One&display=swap");
.WebHeader {
  font-family: var(--fontFamilyForHeader);
  font-weight: 600;
  font-size: 28px;
  margin-top: -75px;
  margin-bottom: 82px;
  color: #3c6b49;
}
.WebHeaderSubHeader {
  font-size: 20px;
  font-family: var(--fontFamilyForHeader);
  color: var(--myColor);
  font-weight: 600;
  margin-left: 1rem;
  height: 100px;
  margin-bottom: 1rem;
  text-align: center;
}
.centerSubHeader {
  display: flex;
  justify-content: space-around;
}
.LogoutDisplaybtn {
  position: relative;
  background-color: white;
  color: #3c6b49;
  margin-top: 96px;
}
.LogoutDisplaybtn:hover div {
  background-color: #3c6b49;
  color: aliceblue;
}
.WebAppNav {
  /* font-family: var(--fontFamilyForHeader); */
  font-weight: 600;
  font-size: 18px;
  padding-left: 1rem;

  /* margin-bottom: 82px; */
  color: white;
}
.sideBarListHover:hover {
  background-color: "#3C6B49";
  color: "white";
}
a {
  background-color: none;
  text-decoration: none;
  color: black;
}
.responsiveHandler {
  padding-left: 242px;
}
body {
  font-family: "Poppins", sans-serif;
}
:root {
  --backgroundColor: #3c6b49;
  --borderRadius: 8px;
  --backgroundColorForCancel: #f2f3f1;
  --btnwidth: 348px;
  --btnheight: 59px;
  --fontFamilyForHeader: "Suez One", serif;
  --myColor: #3c6b49 !important;
}
.materialHandler {
  margin-top: 53px;
  margin-left: -122px;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
.css-46bh2p-MuiCardContent-root {
  padding-bottom: 0px !important;
}

.responsiveHandler {
  padding-left: 242px;
}

@media screen and (max-width: 900px) {
  .responsiveHandler {
    padding-left: 0px;
  }
}
@media screen and (max-width: 600px) {
  .css-1ujnqem-MuiTabs-root {
    overflow: scroll !important;
  }
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
    overflow: scroll !important;
  }
}
/* .css-1ujnqem-MuiTabs-root {
  overflow: scroll !important;
}

.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
  overflow: scroll !important;
} */
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-27.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-41.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-55.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-69.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-83.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-97.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-111.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-125.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-139.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-167.MuiTableCell-alignLeft {
  z-index: 0 !important;
}
th {
  z-index: 0 !important;
}
@media screen and (max-width: 365px) {
  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
    display: block;
  }
}
a {
  cursor: pointer;
}
