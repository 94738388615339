.ImgWidth {
  width: 100%;
  height: 217px;
  /* margin-top: -49px; */
}
.PersonImg {
  width: 135px;
  height: 150px;
  border-radius: 100px;
  position: relative;
  top: -83px;
  left: 40px;
}
.TableValueCell {
  text-transform: capitalize;
}
#personName {
  position: relative;
  top: -86px;
  text-transform: capitalize;
  left: 51px;
  font-size: 20px;
  font-weight: 900;
}
#Pageheader {
  font-size: 20px;
  font-weight: 600;
  padding: 0.5rem;
  color: #5b626b;
}
#tableHeadvalue {
  color: #5b626b;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
}
@media screen and (max-width: 600px) {
  tr.MuiTableRow-root.css-1q1u3t4-MuiTableRow-root {
    display: grid;
  }
}
.MuiFormControl-root.css-2k5wmj-MuiFormControl-root {
  background: white;
}
