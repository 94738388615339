.loginImg{
    height:100vh;
    width: 100%;
}
.loginformContainer{
    width: 80%;
}
.logincontainerResponsive{
    display: flex;
    justify-content: center;
}
@media screen and (max-width:900px){
    .loginImg{
     display: none;
        
    }   
    
}